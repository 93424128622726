const months = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];

export function firstLetterUpperCase(str: string) {
  // Apparently, the string can be undefined.... need to check legacy code if it's defined before using it.. but for now, just return empty string if undefined
  if (!str) return "";
  return str[0] + str.substring(1).toLowerCase();
}

export function getMonthName(index: number) {
  return months[index];
}
